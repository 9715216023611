import * as React from 'react';

import Layout from '../components/simple-layout.js';

const ConfirmSubscription = () => (
  <Layout
    title="Subscription confirmed"
    description="You have subscribed to my newsletter. I hope you enjoy it. Cancel any time!"
  >
    {/* TODO: Extract this layout as a single-page layout */}
    <div className="relative px-4 pt-4 pb-8 mt-4 bg-gray-50 sm:px-6 lg:pt-6 lg:pb-6 lg:px-8">
      <div className="relative mx-auto max-w-7xl">
        <div className="relative py-16 overflow-hidden bg-white">
          <main>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <div className="bg-indigo-700">
                  <div className="max-w-2xl px-4 py-16 mx-auto text-center sm:py-20 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                      <span className="block">
                        Subscription confirmed!
                      </span>
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-indigo-200">
                      Boom! You're officially confirmed and on the
                      list.
                      <br /> Expect some great emails headed your way!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </Layout>
);

export default ConfirmSubscription;
